<template>
  <div>
    <v-dialog persistent v-model="dialog_bulk_category" max-width="800px">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0"
            >New Category</span
          >
        </div>
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-form ref="frm">
              <v-row
                justify="center"
                v-for="(row, i) in editedItem.bulk.length"
                v-bind:key="i"
              >
                <v-col cols="4">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >Category Name</label
                  >
                  <v-text-field
                    v-model="editedItem.bulk[i].category_name"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    dense
                    flat
                    filled
                    solo
                    placeholder="Category"
                    :rules="[(v) => !!v || 'Category name is required']"
                  ></v-text-field>
                </v-col>

                <v-col cols="2" class="pa-1" align-self="center">
                  <span class="pa-2" align-self="center">
                    <v-btn
                      class="mx-2 mt-3 input-style"
                      outlined
                      small
                      color="red"
                      @click="remove(i)"
                      v-show="i || (!i && editedItem.bulk.length > 1)"
                      >Remove
                    </v-btn>
                  </span>
                </v-col>
                <v-col cols="2" class="pa-1" align-self="center">
                  <span class="pa-2" align-self="center">
                    <v-btn
                      class="mx-2 mt-3"
                      outlined
                      small
                      color="green"
                      @click="add()"
                      v-show="i == editedItem.bulk.length - 1"
                      >Add
                    </v-btn>
                  </span>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="cancel"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-secondary
              bg-gradient-light
              py-3
              px-6
            "
            >Cancel</v-btn
          >

          <v-btn
            @click="save"
            elevation="0"
            :ripple="false"
            height="43"
            dark
            class="text-capitalize btn-ls btn-primary bg-success py-3 px-6"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import axios from "axios";
import api from "../api";

export default {
  props: {
    input_data: Array,
    dialog_bulk_category: Boolean,
  },
  name: "CategoryBulk",
  data() {
    return {
      editedItem: {
        bulk: [{ category_name: "" }],
      },
      defaultItem: {
        bulk: [{ category_name: "" }],
      },
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      if (
        this.input_data &&
        this.input_data?.length > 0 &&
        this.input_data != undefined &&
        this.input_data != null
      ) {
        this.editedItem.bulk = this.input_data;
      }
    },
    cancel() {
      // console.log("Cancceled");
      this.$emit("cancel_upload");
    },
    validate() {
      return this.$refs.frm.validate();
    },

    add() {
      this.editedItem.bulk.push({
        category_name: "",
      });
    },

    remove(index) {
      this.editedItem.bulk.splice(index, 1);
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },

    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$emit("close_dialog_bulk_category");
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.validate()) {
        try {
          let type = "Saved";
          await api.createBulk(this.editedItem);
          this.showSuccessAlert(`Category ${type}.`);
          this.close();
        } catch (err) {
          this.showErrorAlert(err.message);
        }
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>
